import { useParams, Link } from 'react-router-dom';
import {useState,useEffect,useRef} from 'react';

function Vote({urlToConnect}){

    const {pollId}=useParams();
    const [pollQuestion,setPollQuestion]=useState('');
    const [optionValues,setOptionValues]=useState('');
    const [linkAndContent,setLinkAndContent]=useState((<></>));
    const [pollStatus,setPollStatus]=useState('');
    const optionRefs=useRef([]);
    const [selectedOption,setSelectedOption]=useState(null);
    const submitButtonRef=useRef(null);
    const [activePollVisibility,setActivePollVisibility]=useState('displayNone');

    useEffect(()=>{
        fetch(`${urlToConnect}/voteDetails/${pollId}`)
           .then(response=>response.json()).then(responseJSON=>{
            
            if(responseJSON.statusMessage==='SUCCESSFUL'){
                setActivePollVisibility('displayBlock');

                setPollQuestion(responseJSON.pollQuestion);
                setOptionValues(responseJSON.optionValues);

                setPollStatus('');
                setLinkAndContent((<></>));
            }
            else if(responseJSON.statusMessage==='INVALID'){
                setPollStatus('Invalid poll!');
                setLinkAndContent((<Link className='colorGreen0 minimalMargin' to='/createPoll'>Create Poll</Link>));
            }
            else {
                setPollStatus('Please try after sometime.');
                setLinkAndContent((<Link className='colorGreen0 minimalMargin' to='/createPoll'>Create Poll</Link>));
            }

            

           });
    },[]);


    function optionSubmission(){

        if(selectedOption!=null){

            submitButtonRef.current.disabled=true;
            submitButtonRef.current.style.backgroundColor='rgb(179,179,179)';
    
           fetch(`${urlToConnect}/voteSubmission`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({'pollId':pollId,'selectedOption':selectedOption})})
           .then(response=>response.json()).then(responseJSON=>{
            
            if(responseJSON.statusMessage==='SUCCESSFUL'){

                setPollStatus('Submitted!');
                setLinkAndContent((<></>));

            }else if(responseJSON.statusMessage==='VOTED'){
                setPollStatus("You've already voted!");
            }
            else if(responseJSON.statusMessage==='INVALID'){
                setPollStatus('Invalid poll!');
                setLinkAndContent((<Link className='colorGreen0 minimalMargin' to='/createPoll'>Create Poll</Link>));
            }
            else {
                setPollStatus('Please try after sometime.');
                setLinkAndContent((<Link className='colorGreen0 minimalMargin' to='/createPoll'>Create Poll</Link>));
            }


           });
}else{
   setPollStatus('Please select an option');
   setLinkAndContent((<></>));
}
    }


    function optionSelected(optionNumber){

        setSelectedOption(optionNumber);

        optionRefs.current.forEach((currentRef)=>{
            currentRef.style.backgroundColor='black';
        })

        optionRefs.current[optionNumber].style.backgroundColor='rgb(55, 160, 7)';

    }


return(<div className='minHeight100vh backgroundBlack displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter'>

<div className={`W80vw backgroundWhite minimalPadding minimalMargin minimalBorderRadius flexDirectionColumn alignItemsCenter justifyContentCenter ${activePollVisibility==='displayBlock'?'displayFlex displayBlock':'displayNone'}`}>
<p className={`readableSymbolSize fontWeightBold colorBlack minimalPadding minimalBorderRadius textAlignCenter ${activePollVisibility}`}>{pollQuestion}</p>

{

optionValues.split('::').map((optionValue,index)=>{

return optionValue&&<button key={index} ref={(currentRef)=>optionRefs.current[index]=currentRef} className={`W50vw readableFontSize fontWeightBold colorWhite backgroundBlack minimalPadding minimalMargin minimalBorderRadius ${activePollVisibility}`} 
onClick={()=>optionSelected(index)}>{optionValue}</button>

}
)

}

<button ref={submitButtonRef} className={`readableFontSize fontWeightBold colorBlack backgroundGreen0 minimalPadding minimalMargin minimalBorderRadiusButton textAlignCenter ${activePollVisibility}`} onClick={optionSubmission}>SUBMIT</button>

</div>

<p className='readableSymbolSize fontWeightBold alignTextCenter textAlignCenter minimalMargin'>{pollStatus}</p>
<p className='readableSymbolSize fontWeightBold alignTextCenter textAlignCenter minimalMargin'>{linkAndContent}</p>

</div>);
}

export default Vote; 