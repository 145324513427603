import {useRef,useState,useEffect} from 'react';
import {Link,useNavigate} from "react-router-dom";

function Login({setLoginStatus, urlToConnect}){

    const loginComponentRef=useRef(null);
    const emailInputComponentRef=useRef(null);
    const passwordInputComponentRef=useRef(null);
    const loginButtonComponentRef=useRef(null);
    const [passwordVisibility,setPasswordVisibility]=useState('password');
    const [passwordVisibilityIcon,setPasswordVisibilityIcon]=useState('/visibility_off.svg');
    const loginStatusRef=useRef(null);
    const [loginStatusMessage,setLoginStatusMessage]=useState('');
    const [signUpLink,setSignUpLink]=useState((<></>));
    const navigate=useNavigate();
    const [loginPageVisibility,setLoginPageVisibility]=useState(false);

    useEffect(()=>{
              
                  fetch(`${urlToConnect}/dashboardDetails`,{method:'GET',credentials:'include'}).then((response)=>response.json()).then(responseJSON=>{
                      if(responseJSON.statusMessage==='SUCCESSFUL'){
                        setLoginStatus('Log out');
                        navigate('/dashboard');
                      }else{
                        setLoginStatus('Log in');
                        setLoginPageVisibility(true);
                      }
                  })

    },[]);    


    function passwordVisibilityToggle(){
        if(passwordVisibility==='password'){
            setPasswordVisibility('text');
            setPasswordVisibilityIcon('/visibility.svg');
        }else{
            setPasswordVisibility('password');
            setPasswordVisibilityIcon('/visibility_off.svg');
        }
    }

    function loginSubmission(){
        const emailAddress=emailInputComponentRef.current.value;
        const password=passwordInputComponentRef.current.value;
        const emailRegex=/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


        if(!emailRegex.test(emailAddress)){
            setLoginStatusMessage('Invalid Email Format');
        }else if(!passwordRegex.test(password)){
            setLoginStatusMessage('Password should have at least 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character (@$!%*?&).');
        }else {
        fetch(`${urlToConnect}/loginSubmission`,{method:'PUT',credentials:'include',headers:{'Content-Type':'application/json'},body:JSON.stringify({'emailAddress':emailAddress,'password':password})})
        .then(response=>{return response.json()})
        .then(responseJSON=>{
            if(responseJSON.statusMessage==='successful'){
                setLoginStatus('Log out');
                navigate('/dashboard');
            }else if(responseJSON.statusMessage==='sign up'){
            setLoginStatusMessage('You do not have an account with us.');
            setSignUpLink((<Link className='colorGreen0 minimalMargin' to='/signup'>Sign Up</Link>));
        }else if(responseJSON.statusMessage==='activation pending'){
            setLoginStatusMessage('Please activate your account, by clicking "Activate" button in the email sent to your email address.');
        }else if(responseJSON.statusMessage==='wrong password'){
            setLoginStatusMessage('Please recheck the entered password.');
        }else {
            setLoginStatusMessage('Sorry, we are having an issue. Please retry after some time.');
        }
        });
    }

    }


    return (<div ref={loginComponentRef} className='W100vw H100vh displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter'>

{loginPageVisibility&&<>
                <div className={`displayFlex flexDirectionColumn`}> 
            
            <input ref={emailInputComponentRef} className={`signUpInput placeHolderOpacity minimalPaddingXTopXBottom minimalMargin outlineNone borderStyleNone minimalBorderRadius`} type='text' minLength={8} maxLength={254} placeholder='Enter your email address'></input>
               
               
                <div className={`signUpInputHolder displayFlex alignItemsCenter backgroundWhite minimalPaddingXTopXBottom minimalMargin borderStyleNone minimalBorderRadius`} >
                <input ref={passwordInputComponentRef} className={`signUpInputPassword placeHolderOpacity borderStyleNone outlineNone minimalBorderRadius minimalPaddingXTopXBottom`} type={passwordVisibility} minLength={8} maxLength={16} placeholder='Enter your password'></input>
                <button onClick={passwordVisibilityToggle}  className={`padding0 backgroundTransparent borderStyleNone`}><img className={`H5vh`} src={passwordVisibilityIcon}></img></button>
                </div>
    
                </div>
        
            <button ref={loginButtonComponentRef} className={`signUpButton minimalPadding minimalMargin fontWeightBold colorBlack backgroundGreen0 borderStyleSolid minimalBorderRadiusButton`} onClick={loginSubmission}>Log in</button>

            <Link className={`readableSymbolSize minimalPadding minimalMargin fontWeightBold colorBlack`} to='/signup'>Sign Up</Link>
            
            <p ref={loginStatusRef} className={`W100vw displayFlex flexDirectionColumn signUpStatus minimalPadding minimalMargin fontWeightBold textAlignCenter`}>{loginStatusMessage}{signUpLink}</p>
            </>}
    </div>);
}

export default Login;